@import url('https://fonts.googleapis.com/css2?family=Anta&family=Ojuju:wght@200..800&family=Unbounded:wght@200..900&display=swap');

body {
  background-color: #000000;
  margin: 0;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

a {
  text-decoration: none; /* Remove underline */
  color: inherit; /* Inherit color from parent element */
}

a:hover {
  text-decoration: none; /* Optional: Add underline on hover if you like */
  color: rgb(255, 136, 0); /* Optional: Change color on hover */
}


.container {
  display: flex;
  height: 100vh; /* Full height of the viewport */
  width: 100vw; /* Full width of the viewport */
}

.page-title {
  font-size: 2rem;
  color: rgb(255, 94, 0);
  margin-bottom: 10px;
  font-family: "Unbounded", sans-serif;
  font-weight: 10%;
}

.left {
  flex: 5;
  padding: 20px;
  background-color: #000000;
  color: aliceblue;
  font-family: "Unbounded", sans-serif;
}

.about-section,
.featured-work,
.projects {
  margin-bottom: 15px;
}

h1{
  font-family: "Unbounded", sans-serif;

}

p {
  font-size: 13px;
  font-weight: 50;
}

h2 {
  font-size: 3rem;
  color: rgb(255, 255, 255);
  font-family: "Unbounded", sans-serif;

}

h3 {
  font-size: 1.45rem;
}

hr {
  border: 2.5px solid rgb(128, 128, 128);
  margin: 15px 50px 10px 0px;
}

.right {
  flex: 5;
  position: relative;
}

.image-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 95%;
  border: 3px solid rgb(0, 0, 0);
}

.text-overlay {
  position: absolute;
  top: 20px;
  left: 20px;
  color: rgb(255, 94, 0);
  font-size: 2.75rem;
  text-align: left;
  padding: 10px;
  max-width: 90%; /* Ensure text stays within the image container */
  box-sizing: border-box;
}

/* Mobile layout: move right section on top */
@media (max-width: 768px) {
  .container {
    flex-direction: column; /* Stack the sections vertically */
  }

  .right {
    order: 1; /* Move the right side (images) to the top */
    flex: none; /* Reset flex */
    height: 50vh; /* Adjust the height for mobile view */
  }

  .left {
    order: 2; /* Move the left side (content) to the bottom */
    flex: none; /* Reset flex */
    height: auto; /* Let the content take its natural height */
  }

  .image-container {
    height: 50vh; /* Fixed height for image container */
    overflow: hidden; /* Ensure no content leakage */
  }

  .background-image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensure images cover the container fully */
  }

  .text-overlay {
    font-size: 1rem; /* Adjust font size for smaller screens */
    padding: 10px; /* Reduce padding for mobile view */
    width: 90%; /* Make sure it fits within the screen */
    left: 5%; /* Center horizontally by giving space on the left */
    right: 5%; /* Add space on the right as well */
    box-sizing: border-box; /* Include padding in width */
  }
}


/* Mid-size screens: Tablets, iPads, and MacBooks */
@media (min-width: 768px) and (max-width: 1024px) {
  .container {
    display: flex;
    height: 100vh;
    overflow: hidden;
  }

  .left {
    flex: 5;
    padding: 20px;
    background-color: #000000;
    color: aliceblue;
    font-family: Arial, Helvetica, sans-serif;
    overflow-y: auto;
  }

  .right {
    flex: 5;
    position: relative;
    overflow: hidden;
  }

  .image-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .background-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 95%;
    border: 3px solid rgb(0, 0, 0);
  }

  .text-overlay {
    position: absolute;
    top: 20px;
    left: 20px;
    color: rgb(255, 94, 0);
    font-size: 1rem;
    text-align: left;
    padding: 10px;
    max-width: 90%;
    box-sizing: border-box;
  }

  hr {
    border: 2.5px solid rgb(128, 128, 128);
    margin: 15px 50px 10px 0px;
  }
}

/* Adjustments for 1440x900 */
@media (min-width: 900px) and (max-width: 1440px) {
  .container {
    display: flex;
    height: 100vh;
    overflow: hidden;
  }

  .left {
    flex: 5;
    padding: 20px;
    background-color: #000000;
    color: aliceblue;
    font-family: Arial, Helvetica, sans-serif;
    overflow-y: auto;
  }

  .right {
    flex: 5;
    position: relative;
    overflow: hidden;
  }

  .image-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .background-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 95%;
    border: 3px solid rgb(0, 0, 0);
  }

  .text-overlay {
    position: absolute;
    top: 20px;
    left: 20px;
    color: rgb(255, 94, 0);
    font-size: 1.35rem;
    text-align: left;
    padding: 10px;
    max-width: 90%;
    box-sizing: border-box;
  }

  hr {
    border: 2.5px solid rgb(128, 128, 128);
    margin: 15px 50px 10px 0px;
  }
}

